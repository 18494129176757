<template>
  <div class="coupon text-[0.875em] rounded-rounded text-[#474f51]">
    <div :class="headerClass">
      <div class="flex-c-sb font-bold uppercase">
        <div class="whitespace-pre">
          {{ formattedDate }} {{ typeEx }}
          <span v-if="typeEx === 'express'">({{ eventsToGuessNum }})</span>
          <span v-else-if="typeEx === 'system'"
            >({{ eventsToGuessNum }} / {{ selectionsNumber }})</span
          >
        </div>
        <div v-if="eventsToGuessNum > 1">{{ formattedOdds }}</div>
      </div>
    </div>
    <div v-for="(ev, idx) in events" :key="idx" :class="eventClass(ev.status, idx)">
      <div class="flex-c-sb">
        <div class="font-light">{{ ev.name }}</div>
        <div class="font-bold">{{ formatOdds(ev.odds) }}</div>
      </div>
      <div class="flex-c-sb font-bold">
        <div class="text-keppel">{{ ev.sport }}</div>
        <div>{{ ev.categoryName }}</div>
      </div>
      <div class="flex-c-sb">
        <div class="whitespace-pre">
          <span class="font-bold">{{ ev.selName }}</span>
          <span class="font-light">({{ ev.marketName }})</span>
        </div>
        <div class="size-5.5">
          <svg
            v-if="ev.status === 'win'"
            xmlns="http://www.w3.org/2000/svg"
            width="100%"
            height="100%"
            viewBox="0 0 22 23"
          >
            <path
              class="green-light"
              fill-rule="evenodd"
              d="M11 22.904c6.075 0 11-4.925 11-11s-4.925-11-11-11-11 4.925-11 11 4.925 11 11 11Zm-.869-4.759 8.108-9.266-2.258-1.975-5.893 6.734L6.22 9.383 4 11.4l6.131 6.744Z"
            />
          </svg>
          <svg
            v-else-if="ev.status === 'lose'"
            xmlns="http://www.w3.org/2000/svg"
            width="100%"
            height="100%"
            viewBox="0 0 22 23"
          >
            <path
              class="red-light"
              fill-rule="evenodd"
              d="M11 22.904c6.075 0 11-4.925 11-11s-4.925-11-11-11-11 4.925-11 11 4.925 11 11 11ZM16.85 8.56l-3.535 3.535 3.535 3.536-2.121 2.121-3.536-3.536-3.535 3.536-2.122-2.121 3.536-3.536-3.535-3.535 2.12-2.122 3.536 3.536 3.536-3.536 2.121 2.122Z"
            />
          </svg>
        </div>
      </div>
    </div>
    <div :class="footerClass">
      <hr class="text-blue-bayoux border-1 my-0" />
      <div class="coupon-footer">
        <div class="tl font-light whitespace-pre">
          {{ _("boilerplate.components.SportBetsHistoryModal.Bet") }}:
          <span class="font-bold">{{ formattedBet }}</span>
        </div>
        <div
          v-if="outcomeStatus === 'win'"
          class="tr flex items-baseline justify-end font-light whitespace-pre"
        >
          {{ _("boilerplate.components.SportBetsHistoryModal.Win") }}:
          <span class="font-bold">{{ formattedWin }}</span>
          <svg class="size-3.5 ml-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 15">
            <path
              class="fill-keppel"
              d="M14 8.275 7.368.905.734 8.275h3.683v6.628h5.896V8.275h3.688Z"
            />
          </svg>
        </div>
        <div v-else-if="outcomeStatus === 'lost'" class="tr font-bold">
          {{ _("boilerplate.components.SportBetsHistoryModal.Lost") }}
        </div>
        <div v-else-if="status === 'cashout'" class="tr font-light whitespace-pre uppercase">
          <div class="font-bold">{{ formattedWin }}</div>
        </div>
        <div class="text-[0.625em] bl text-dusty-gray self-end">
          {{ _("boilerplate.components.SportBetsHistoryModal.Id") }}:
          {{ formattedUid }}
        </div>
        <div v-if="status === 'cashout'" class="text-[0.875em] br font-bold self-end uppercase">
          {{ _("boilerplate.components.SportBetsHistoryModal.Cashout") }}
        </div>
        <div v-if="isCancelled" class="text-[0.875em] tr font-bold uppercase">
          {{ _("boilerplate.components.SportBetsHistoryModal.Cancelled") }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "Coupon",
  props: {
    createdAt: String,
    uid: String,
    eventsToGuessNum: Number,
    selectionsNumber: Number,
    typeEx: String,
    odds: [String, Number],
    bet: [String, Number],
    win: [String, Number],
    currency: String,
    status: String,
    outcomeStatus: String,
    events: Array,
  },

  computed: {
    ...mapGetters({
      _: "l10n/translate",
      currency: "user/currency",
    }),
    formattedDate() {
      return new Date(this.createdAt).toLocaleDateString();
    },
    formattedUid() {
      return this.uid.match(/.{1,4}/g)?.join(" ") ?? this.uid;
    },
    formattedOdds() {
      return this.formatOdds(this.odds);
    },
    formattedBet() {
      return this.formatMoney(this.bet);
    },
    formattedWin() {
      return this.formatMoney(this.win);
    },
    isCancelled() {
      return this.status === "cancelled" || this.outcomeStatus === "cancelled";
    },
    headerClass() {
      return {
        "px-5 py-2.5": true,
        "green-light": this.outcomeStatus === "win",
        "red-light": this.outcomeStatus === "lost",
        "bg-mercury": !["win", "lost"].includes(this.outcomeStatus),
      };
    },
    footerClass() {
      return `px-5 bg-athens-gray status-win coupon-status_${this.outcomeStatus}`;
    },
  },
  methods: {
    truncFraction(num, digits) {
      const factor = Math.pow(10, digits);
      return Math.floor(Number(num) * factor) / factor;
    },
    formatOdds(num) {
      return this.truncFraction(num, 2);
    },
    formatMoney(num) {
      return `${this.$f.formatMoney(this.$f.toMoney(num))} ${this.currency}`;
    },
    eventClass(status, idx) {
      let classes = "px-5 py-2.5 gap-2.5 bg-athens-gray flex flex-col overflow-hidden";

      if (idx > 0) {
        classes += " border-0 border-dashed border-t-2 border-mercury rounded-t-xl";
      }

      if (this.events.length > 1 && idx !== this.events.length - 1) {
        classes += " rounded-b-xl";
      }

      classes += ` coupon-status_${status}`;

      return classes;
    },
  },
};
</script>
<style>
.red-light {
  background-color: #f31414;
  color: white;
  fill: #f31414;
}

.green-light {
  background-color: #45a604;
  color: white;
  fill: #45a604;
}
</style>
